var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('Banner'),_c('div',{staticClass:"scrollable-wrapper"},[_c('Sports',{attrs:{"live":true}})],1),_vm._l((_vm.liveSports),function(sport){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(sport.games < 1),expression:"sport.games < 1"}],key:sport.sport_id,staticClass:"no-games-message"},[_vm._v(" "+_vm._s(_vm.$t("checkBackSoon"))),_c('br'),_c('a',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.setSportMenu(1, 'Soccer')}}},[_vm._v(" "+_vm._s(_vm.$t("playMoreGames"))+" ")])])}),(_vm.livedata.length < 1 && !_vm.loading)?_c('div',[_c('NoData',{attrs:{"title":_vm.$t('noLiveGameForSport'),"description":_vm.$t('kindlyCheckBackLater')}})],1):_vm._e(),_vm._l((_vm.livedata),function(game){return _c('div',{key:game.match_id,staticClass:"game-card"},[_c('div',{staticClass:"game-type"},[_c('div',{staticClass:"country"},[_vm._v(" "+_vm._s(game.country)+" • "+_vm._s(_vm.shortenName(game.tournament, 40))+" ")]),_c('div',{staticClass:"date"},[_c('div',[_c('span',[_c('img',{staticClass:"blinking-image",staticStyle:{"width":"15px"},attrs:{"src":"/img/live-video.png"}}),_vm._v(" "+_vm._s(game.event_time)+" / "),_c('span',{staticStyle:{"color":"var(--red)"}},[_vm._v(" "+_vm._s(game.match_status)+" ")])])])])]),_c('div',{staticClass:"games"},[_c('div',{staticClass:"px-0 game-card-teams"},[_c('router-link',{staticClass:"match d-block font-weight-bold",staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'match',
            params: {
              game_id: game.game_id === undefined ? 0 : game.game_id,
              name: _vm.toURL(game.name),
              status: _vm.live ? 'live' : 'prematch',
            },
          }}},[_c('div',[_c('span',{staticClass:"score",domProps:{"textContent":_vm._s(game.home_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(game.home_team, 20))+" ")]),_c('div',{staticClass:"mb-0"},[_c('span',{staticClass:"score",domProps:{"textContent":_vm._s(game.away_score)}}),_vm._v(" "+_vm._s(_vm.shortenName(game.away_team, 20))+" ")])])],1),_c('div',{staticClass:"odds"},_vm._l((game.highlight_market.outcomes),function(outcome){return _c('Odd',{key:outcome.outcome_id,class:_vm.getTeamClass(outcome.alias),staticStyle:{"margin":"0px !important","padding":"2px !important","height":"fit-content"},attrs:{"date":game.date,"match_id":game.match_id,"market_name":game.highlight_market.market_name,"market_id":game.highlight_market.market_id,"status_name":game.highlight_market.status_name,"market_status":game.highlight_market.status,"specifier":game.highlight_market.specifier,"home_team":game.home_team,"away_team":game.away_team,"pick":outcome,"producer_id":game.producer_id,"producer_status":game.producer_status,"status":game.status,"sport_id":game.sport_id,"outcome_id":outcome.outcome_id,"odds":outcome.odds,"alias":outcome.alias,"active":outcome.active,"probability":outcome.probability,"previous_odds":outcome.previous_odds}})}),1)]),_c('div',{staticClass:"markets"},[_c('p',[_vm._v("+"+_vm._s(game.active_markets)+" "+_vm._s(_vm.$t("markets")))])])])}),(_vm.loading)?_c('div',{staticClass:"load page_container"},[_c('FixturesSkeletonLoader',{attrs:{"count":10}})],1):_vm._e(),(_vm.livedata)?_c('div',[_c('scroll-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.livedata.length > 0),expression:"livedata.length > 0"}],attrs:{"loader-method":_vm.loadOnScroll,"loader-disable":_vm.is_busy || _vm.page >= _vm.last_page}},[_c('div')])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }